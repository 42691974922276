@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", cursive;
}

body {
  background-color: #1a1a1a;
  color: #fff;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d2d2d;
  padding: 10px 20px;
  border-bottom: 4px solid #3d3d3d;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #f0cb7f;
  text-decoration: none;
  font-size: 16px;
}

.demo-button {
  background-color: #f0cb7f;
  color: #333;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

.hero {
  position: relative;
  height: 400px;
  background: linear-gradient(to bottom, #597791, #e8a978);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.game-banner {
  position: relative;
  height: 4rem;
  color: #f0cb7f;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-content {
  z-index: 2;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 3px 3px 0 #000;
  letter-spacing: 2px;
}

.hero p {
  font-size: 16px;
  color: #fff;
  text-shadow: 2px 2px 0 #000;
}

.sun {
  position: absolute;
  width: 150px;
  height: 75px;
  background-color: #ffe8a8;
  border-radius: 75px 75px 0 0;
  bottom: 100px;
  right: 100px;
}

.sun::after {
  content: "--- \A ----- \A--------";
  position: absolute;
  top: 20px;
  left: 20px;
  color: #333;
  font-size: 10px;
}

.cityscape {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  /* stylelint-disable-next-line */
  background-color: rgba(30, 50, 50, 0.7);
  /* stylelint-disable-next-line */
  clip-path: polygon(0% 50%, 5% 40%, 10% 50%, 15% 20%, 20% 30%, 25% 20%, 30% 10%, 35% 15%, 40% 5%, 45% 15%, 50% 5%, 55% 20%, 60% 10%, 65% 20%, 70% 10%, 75% 30%, 80% 20%, 85% 30%, 90% 40%, 95% 30%, 100% 50%, 100% 100%, 0% 100%);
}

.pixelated-characters {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 20px;
}

.character {
  width: 20px;
  height: 30px;
  background-color: #333;
}

.car {
  width: 60px;
  height: 30px;
  background-color: #444;
  position: absolute;
  bottom: 20px;
  right: 200px;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #222;
}

.game-section-title {
  grid-column: 1 / -1;
  padding: 10px;
  font-size: 24px;
  color: #f0cb7f;
  text-align: center;
}

.game-card {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.game-image {
  width: 100%;
  height: 150px;
  background-color: #222;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-start {
  background-color: #333;
  padding: 10px;
}

.game-title {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center;
}

.game-description {
  font-size: 8px;
  color: #aaa;
  text-align: center;
  padding: 0 5px;
}

.frame-wrapper {
  background-color: #1a1a1a;
  height: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

#game-frame {
  border-radius: 10px;
  width: 95%;
  height: 95%;
  border: none;
}

.home-icon {
  padding: 20px;
}
